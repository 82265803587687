<template>
  <VForm ref="form">
    <VRow class="ma-0">
      <VCol class="px-2">
        <VMenu
          nudge-bottom="50"
          nudge-width="200"
          max-height="400"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <div>
              <VBtn
                color="primary"
                dark
                id="ms-search-form-network"
                v-bind="attrs"
                :disabled="!networkList.length"
                small
                v-on="on"
              >
                {{ $t('grid.network') }}
              </VBtn>
            </div>
          </template>

          <template #default>
            <VCard
              v-click-outside="confirmChanges"
              class="pa-4"
            >
              <VTextField
                v-model="search"
                :label="$t('grid_monitor.search')"
                clear-icon="mdi-close-circle-outline"
                prepend-inner-icon="mdi-magnify"
                class="px-4"
                clearable
              />

              <VCol>
                <VBtn
                  small
                  class="select-all-button"
                  @click="onSelectAllClick"
                >
                  {{ $t('grid_monitor.select_all_stations') }}
                </VBtn>
                <VBtn
                  small
                  class="reset-button"
                  :disabled="!stations.length"
                  @click="onResetStationClick"
                >
                  {{ $t('reset') }}
                </VBtn>
              </VCol>

              <VList flat>
                <VListItem
                  v-for="item in filteredStations"
                  :key="item.ID"
                  @click="onStationClick(item)"
                >
                  <template #default>
                    <VListItemAction>
                      <VCheckbox :input-value="validateInputValue(item.ID)" />
                    </VListItemAction>

                    <VListItemTitle>
                      {{ item.NAME }}
                    </VListItemTitle>
                  </template>
                </VListItem>
              </VList>
            </VCard>
          </template>
        </VMenu>
      </VCol>
    </VRow>
  </VForm>
</template>

<script>
import { mapState } from 'vuex'

import areaGridMixin from '@/mixins/areaGridMixin'
import vuexMixin from '@/mixins/vuexMixin'
import {
  getAssetMapByZone,
  getAssetMapByElementId,
  getAssetMapByElementName
} from '@/services/measurements'

export default {
  name: 'MSSearchForm',

  mixins: [areaGridMixin, vuexMixin],

  directives: {
    clickOutside: () => import('@/directives/clickOutside')
  },

  props: {
    zone: {
      type: Number,
      default: null
    },
    element: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    search: null,
    stations: [],
    networkList: [],
    emptyNetworkList: false,
    unconfirmedChanges: false
  }),

  computed: {
    ...mapState({
      selectedStationsIds: ({ measurements }) => measurements.selectedStationsIds,
    }),

    filteredStations () {
      if (this.search) {
        return this.networkList.filter(item => {
          const formattedName = item.NAME.toLowerCase()

          return String(item.ID).includes(this.search) ||
            formattedName.includes(this.search.toLowerCase())
        })
      }

      return this.networkList
    },

    queryString () {
      if (!this.stations.length) return ''

      const STATIONS_VAR = 'stations[]='

      return this.stations.reduce((query, station, index) => {
        const isLastIndex = index === this.stations.length - 1
        return query.concat(isLastIndex ? `${station.ID}` : `${station.ID}&${STATIONS_VAR}`)
      }, STATIONS_VAR)
    },

    stationsIdsNames () {
      return this.stations.reduce((obj, next) => {
        const value = this.networkList.find(el => el.ID === next.ID) || {}

        return {
          ids: [...obj.ids, value.ID],
          names: [...obj.names, value.NAME]
        }
      }, { ids: [], names: []})
    }
  },

  watch: {
    zone: {
      deep: false,
      immediate: true,
      async handler (val) {
        if (val) {
          await this.setNetworkListByZone()
        }
      }
    },

    element: {
      deep: false,
      immediate: true,
      async handler (val) {
        if (val) {
          await this.setNetworkListByElement()
        }
      }
    },

    stationsIdsNames: {
      deep: true,
      handler () {
        this.unconfirmedChanges = true
      }
    }
  },

  methods: {
    onStationClick (station) {
      const { ID: stationId } = station

      if (this.stationsIdsNames.ids.includes(stationId)) {
        const elementIndex = this.stationsIdsNames.ids.indexOf(stationId)
        this.stations.splice(elementIndex, 1)
      } else {
        this.stations.push(station)
      }
    },

    async setNetworkListByZone () {
      const networkList = await getAssetMapByZone(this.zone)
      if (networkList) {
        this.emptyTree = networkList.length === 0
        this.networkList = networkList

        this.setVuexElement({
          path: 'measurements.area',
          value: this.zone
        })

        this.setVuexElement({
          path: 'measurements.element',
          value: null
        })

        await this.getAreaGrid({
          isZone: true,
          value: this.zone
        })
      }
    },

    async setNetworkListByElement () {
      let originalNetworkList
      const FALSY_VALUES = [undefined, '', 'null', null]

      const hasElementId = !FALSY_VALUES.includes(this.element.id)
      const hasElementName = !FALSY_VALUES.includes(this.element.name)

      if (hasElementId) {
        originalNetworkList = await getAssetMapByElementId(this.element)
      }

      if (!hasElementId && hasElementName) {
        originalNetworkList = await getAssetMapByElementName(this.element)
      }

      if (originalNetworkList) {
        this.emptyNetworkList = originalNetworkList.length === 0

        this.setVuexElement({
          path: 'measurements.element',
          value: this.element
        })

        this.setVuexElement({
          path: 'measurements.area',
          value: null
        })

        await this.getAreaGrid({ value: this.element })
      }
    },

    onSelectAllClick () {
      this.stations = []
      this.filteredStations.forEach(station => {
        this.stations.push(station)
      })
    },

    onResetStationClick () {
      this.stations = []
    },

    validateInputValue (stationId) {
      return this.stationsIdsNames.ids.includes(stationId)
    },

    confirmChanges () {
      const { ids = [], names = [] } = this.stationsIdsNames

      if (String(ids) !== String(this.selectedStationsIds)) {
        this.setVuexElement({
            root: 'measurements',
            path: 'measurements.checkedAssets',
            value: {
              ...this.$store.state.measurements.checkedAssets,
              queryString: this.queryString
            }
          })
  
        this.setVuexElement({
          path: 'measurements.selectedStationsIds',
          value: [...ids]
        })
  
        this.setVuexElement({
          path: 'measurements.selectedNetworksNames',
          value: [...names]
        })
  
        this.unconfirmedChanges = false
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-list-item__title {
  text-align: start;
  margin-left: 15px;
}

::v-deep .v-list-item {
  min-height: 10px !important;
}

::v-deep .v-list-item__action {
  margin: 0 !important;
}

::v-deep .v-list {
  padding: 0 0 10px 0 !important;
}

::v-deep .v-input {
  padding: 5px 15px;
}

::v-deep .v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1 !important;
}

.select-all-button, .reset-button {
  margin-right: 10px;
}

.font-small {
  font-size: 10px;
}
</style>
